import React from "react";
import styles from "@aspen/theme/Home.module.less";
import { getStrategyList } from "@aspen/services";
import { IInjectProps, IStrategyDetail } from "@aspen/model";
import dynamic from "next/dynamic";
import { withRouter } from "next/router";
import {
  i18nUtil,
  reportEvent,
  certifiedKycLevel,
  GA_EVENT_NAME,
  STRATEGIES_PATHS
} from "@aspen/libs";
import { NotKyc2Mask } from "./DynamicImport";

interface IProps extends IInjectProps {}
interface IState {
  strategyList: IStrategyDetail[];
}
const StrategyItem = dynamic(() => import("../strategy/strategy/StrategyItem"), {
  loading: () => <></>
});

class WellStrategy extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      strategyList: []
    };
    this.handleClickOnViewAll = this.handleClickOnViewAll.bind(this);
  }

  handleClickOnViewAll(): void {
    reportEvent({ moduleName: GA_EVENT_NAME.home.viewAllStrategy });
    this.props.router.push(STRATEGIES_PATHS.STRATEGIES);
  }

  componentDidMount(): void {
    // 默认不分页 显示全部策略
    let params = {
      limit: 100,
      nextToken: null
    };

    getStrategyList(params).then((res) => {
      if (res?.code == "0") {
        this.setState({ strategyList: res?.data?.rows ?? [] });
      }
    });
  }

  render(): React.ReactNode {
    const intl = i18nUtil.t();
    const { strategyList } = this.state;
    return (
      <div>
        {strategyList && strategyList?.length > 0 && (
          <section className={styles.wellStrategy}>
            {!certifiedKycLevel(2) && <NotKyc2Mask />}
            <div style={{ filter: certifiedKycLevel(2) ? "" : "blur(2px)" }}>
              <p className={styles.recommendedTitle}>{intl["overview.strategy.title"]}</p>
              <div className={styles.strategies}>
                {strategyList.slice(0, 3).map((item) => (
                  <div className={styles.strategyListItem} key={item.id}>
                    <StrategyItem data={item} key={item.id} />
                  </div>
                ))}
              </div>
              <p className={styles.viewAll} onClick={this.handleClickOnViewAll}>
                {intl["overview.strategy.view.all"]} &gt;
              </p>
            </div>
          </section>
        )}
      </div>
    );
  }

  // shouldComponentUpdate(nextProps, nextState): boolean {
  //   return false;
  // }
}
export default withRouter(WellStrategy);
